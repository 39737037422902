exports.components = {
  "component---src-templates-alternate-js": () => import("./../../../src/templates/alternate.js" /* webpackChunkName: "component---src-templates-alternate-js" */),
  "component---src-templates-articles-article-js": () => import("./../../../src/templates/articles/article.js" /* webpackChunkName: "component---src-templates-articles-article-js" */),
  "component---src-templates-articles-articles-js": () => import("./../../../src/templates/articles/articles.js" /* webpackChunkName: "component---src-templates-articles-articles-js" */),
  "component---src-templates-articles-featured-articles-js": () => import("./../../../src/templates/articles/featured-articles.js" /* webpackChunkName: "component---src-templates-articles-featured-articles-js" */),
  "component---src-templates-blog-author-js": () => import("./../../../src/templates/blog/author.js" /* webpackChunkName: "component---src-templates-blog-author-js" */),
  "component---src-templates-blog-blog-js": () => import("./../../../src/templates/blog/blog.js" /* webpackChunkName: "component---src-templates-blog-blog-js" */),
  "component---src-templates-blog-blogs-js": () => import("./../../../src/templates/blog/blogs.js" /* webpackChunkName: "component---src-templates-blog-blogs-js" */),
  "component---src-templates-blog-blogs-topic-js": () => import("./../../../src/templates/blog/blogs-topic.js" /* webpackChunkName: "component---src-templates-blog-blogs-topic-js" */),
  "component---src-templates-blog-case-studies-js": () => import("./../../../src/templates/blog/case-studies.js" /* webpackChunkName: "component---src-templates-blog-case-studies-js" */),
  "component---src-templates-dashboard-examples-dashboard-example-category-js": () => import("./../../../src/templates/dashboard-examples/dashboard-example-category.js" /* webpackChunkName: "component---src-templates-dashboard-examples-dashboard-example-category-js" */),
  "component---src-templates-dashboard-examples-dashboard-example-js": () => import("./../../../src/templates/dashboard-examples/dashboard-example.js" /* webpackChunkName: "component---src-templates-dashboard-examples-dashboard-example-js" */),
  "component---src-templates-dashboard-examples-dashboard-examples-js": () => import("./../../../src/templates/dashboard-examples/dashboard-examples.js" /* webpackChunkName: "component---src-templates-dashboard-examples-dashboard-examples-js" */),
  "component---src-templates-kpi-examples-kpi-example-category-js": () => import("./../../../src/templates/kpi-examples/kpi-example-category.js" /* webpackChunkName: "component---src-templates-kpi-examples-kpi-example-category-js" */),
  "component---src-templates-kpi-examples-kpi-example-js": () => import("./../../../src/templates/kpi-examples/kpi-example.js" /* webpackChunkName: "component---src-templates-kpi-examples-kpi-example-js" */),
  "component---src-templates-kpi-examples-kpi-example-parent-category-js": () => import("./../../../src/templates/kpi-examples/kpi-example-parent-category.js" /* webpackChunkName: "component---src-templates-kpi-examples-kpi-example-parent-category-js" */),
  "component---src-templates-kpi-examples-kpi-examples-js": () => import("./../../../src/templates/kpi-examples/kpi-examples.js" /* webpackChunkName: "component---src-templates-kpi-examples-kpi-examples-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-lp-js": () => import("./../../../src/templates/lp.js" /* webpackChunkName: "component---src-templates-lp-js" */),
  "component---src-templates-metric-stack-metric-stack-archive-js": () => import("./../../../src/templates/metric-stack/metric-stack-archive.js" /* webpackChunkName: "component---src-templates-metric-stack-metric-stack-archive-js" */),
  "component---src-templates-metric-stack-metric-stack-episode-js": () => import("./../../../src/templates/metric-stack/metric-stack-episode.js" /* webpackChunkName: "component---src-templates-metric-stack-metric-stack-episode-js" */),
  "component---src-templates-resources-analytics-category-js": () => import("./../../../src/templates/resources/analytics/category.js" /* webpackChunkName: "component---src-templates-resources-analytics-category-js" */),
  "component---src-templates-resources-analytics-page-js": () => import("./../../../src/templates/resources/analytics/page.js" /* webpackChunkName: "component---src-templates-resources-analytics-page-js" */),
  "component---src-templates-warehouse-js": () => import("./../../../src/templates/warehouse.js" /* webpackChunkName: "component---src-templates-warehouse-js" */),
  "component---src-templates-webinar-js": () => import("./../../../src/templates/webinar.js" /* webpackChunkName: "component---src-templates-webinar-js" */),
  "component---src-templates-webinars-js": () => import("./../../../src/templates/webinars.js" /* webpackChunkName: "component---src-templates-webinars-js" */),
  "component---src-www-404-js": () => import("./../../../src/www/404.js" /* webpackChunkName: "component---src-www-404-js" */),
  "component---src-www-about-awards-js": () => import("./../../../src/www/about/awards.js" /* webpackChunkName: "component---src-www-about-awards-js" */),
  "component---src-www-about-careers-js": () => import("./../../../src/www/about/careers.js" /* webpackChunkName: "component---src-www-about-careers-js" */),
  "component---src-www-about-contact-us-js": () => import("./../../../src/www/about/contact-us.js" /* webpackChunkName: "component---src-www-about-contact-us-js" */),
  "component---src-www-about-index-js": () => import("./../../../src/www/about/index.js" /* webpackChunkName: "component---src-www-about-index-js" */),
  "component---src-www-about-story-of-klipfolio-js": () => import("./../../../src/www/about/story-of-klipfolio.js" /* webpackChunkName: "component---src-www-about-story-of-klipfolio-js" */),
  "component---src-www-branding-guidelines-index-js": () => import("./../../../src/www/branding-guidelines/index.js" /* webpackChunkName: "component---src-www-branding-guidelines-index-js" */),
  "component---src-www-branding-guidelines-klipfolio-badge-js": () => import("./../../../src/www/branding-guidelines/klipfolio-badge.js" /* webpackChunkName: "component---src-www-branding-guidelines-klipfolio-badge-js" */),
  "component---src-www-branding-guidelines-klipfolio-colours-js": () => import("./../../../src/www/branding-guidelines/klipfolio-colours.js" /* webpackChunkName: "component---src-www-branding-guidelines-klipfolio-colours-js" */),
  "component---src-www-branding-guidelines-klipfolio-logo-js": () => import("./../../../src/www/branding-guidelines/klipfolio-logo.js" /* webpackChunkName: "component---src-www-branding-guidelines-klipfolio-logo-js" */),
  "component---src-www-business-intelligence-and-analytics-software-js": () => import("./../../../src/www/business-intelligence-and-analytics-software.js" /* webpackChunkName: "component---src-www-business-intelligence-and-analytics-software-js" */),
  "component---src-www-capterra-js": () => import("./../../../src/www/capterra.js" /* webpackChunkName: "component---src-www-capterra-js" */),
  "component---src-www-clients-js": () => import("./../../../src/www/clients.js" /* webpackChunkName: "component---src-www-clients-js" */),
  "component---src-www-compare-js": () => import("./../../../src/www/compare.js" /* webpackChunkName: "component---src-www-compare-js" */),
  "component---src-www-comparison-js": () => import("./../../../src/www/comparison.js" /* webpackChunkName: "component---src-www-comparison-js" */),
  "component---src-www-dashboard-templates-js": () => import("./../../../src/www/dashboard-templates.js" /* webpackChunkName: "component---src-www-dashboard-templates-js" */),
  "component---src-www-dashboards-js": () => import("./../../../src/www/dashboards.js" /* webpackChunkName: "component---src-www-dashboards-js" */),
  "component---src-www-index-js": () => import("./../../../src/www/index.js" /* webpackChunkName: "component---src-www-index-js" */),
  "component---src-www-klips-js": () => import("./../../../src/www/klips.js" /* webpackChunkName: "component---src-www-klips-js" */),
  "component---src-www-legal-js": () => import("./../../../src/www/legal.js" /* webpackChunkName: "component---src-www-legal-js" */),
  "component---src-www-marketplace-leader-data-visualization-g-2-crowd-js": () => import("./../../../src/www/marketplace-leader-data-visualization-g2-crowd.js" /* webpackChunkName: "component---src-www-marketplace-leader-data-visualization-g-2-crowd-js" */),
  "component---src-www-migrating-ga-4-js": () => import("./../../../src/www/migrating-ga4.js" /* webpackChunkName: "component---src-www-migrating-ga-4-js" */),
  "component---src-www-powermetrics-for-stripe-js": () => import("./../../../src/www/powermetrics-for-stripe.js" /* webpackChunkName: "component---src-www-powermetrics-for-stripe-js" */),
  "component---src-www-powermetrics-index-js": () => import("./../../../src/www/powermetrics/index.js" /* webpackChunkName: "component---src-www-powermetrics-index-js" */),
  "component---src-www-powermetrics-integrations-index-js": () => import("./../../../src/www/powermetrics/integrations/index.js" /* webpackChunkName: "component---src-www-powermetrics-integrations-index-js" */),
  "component---src-www-powermetrics-integrations-semantic-layer-dbt-js": () => import("./../../../src/www/powermetrics/integrations/semantic-layer/dbt.js" /* webpackChunkName: "component---src-www-powermetrics-integrations-semantic-layer-dbt-js" */),
  "component---src-www-powermetrics-integrations-semantic-layer-index-js": () => import("./../../../src/www/powermetrics/integrations/semantic-layer/index.js" /* webpackChunkName: "component---src-www-powermetrics-integrations-semantic-layer-index-js" */),
  "component---src-www-powermetrics-partner-program-js": () => import("./../../../src/www/powermetrics/partner-program.js" /* webpackChunkName: "component---src-www-powermetrics-partner-program-js" */),
  "component---src-www-press-media-resources-js": () => import("./../../../src/www/press/media-resources.js" /* webpackChunkName: "component---src-www-press-media-resources-js" */),
  "component---src-www-pricing-company-plan-js": () => import("./../../../src/www/pricing/company-plan.js" /* webpackChunkName: "component---src-www-pricing-company-plan-js" */),
  "component---src-www-pricing-index-js": () => import("./../../../src/www/pricing/index.js" /* webpackChunkName: "component---src-www-pricing-index-js" */),
  "component---src-www-reference-js": () => import("./../../../src/www/reference.js" /* webpackChunkName: "component---src-www-reference-js" */),
  "component---src-www-request-demo-js": () => import("./../../../src/www/request-demo.js" /* webpackChunkName: "component---src-www-request-demo-js" */),
  "component---src-www-resources-analytics-social-media-analytics-js": () => import("./../../../src/www/resources/analytics/social-media/analytics.js" /* webpackChunkName: "component---src-www-resources-analytics-social-media-analytics-js" */),
  "component---src-www-resources-index-js": () => import("./../../../src/www/resources/index.js" /* webpackChunkName: "component---src-www-resources-index-js" */),
  "component---src-www-saas-analytics-js": () => import("./../../../src/www/saas-analytics.js" /* webpackChunkName: "component---src-www-saas-analytics-js" */),
  "component---src-www-services-js": () => import("./../../../src/www/services.js" /* webpackChunkName: "component---src-www-services-js" */),
  "component---src-www-v-2-js": () => import("./../../../src/www/v2.js" /* webpackChunkName: "component---src-www-v-2-js" */),
  "component---src-www-whats-new-js": () => import("./../../../src/www/whats-new.js" /* webpackChunkName: "component---src-www-whats-new-js" */)
}

